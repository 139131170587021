<template>
  <div class="Aboutus">
    <div class="bigImg">
    </div>
    <div class="amTitle">
      <p @click="changeModel">
        <span :class="control ? 'clickSpan' : '' ">公司简介</span>
        <img v-if="control" src="../../static/aboutus/amRed.png" alt="">
      </p>
      <p @click="changeModel">
        <span :class="!control ? 'clickSpan' : '' ">合作媒体</span>
        <img v-if="!control" src="../../static/aboutus/amRed.png" alt="">
      </p>
    </div>
    <div v-if="control" class="contentBox">
      <ul class="contentTop">
        <li>
          <img src="../../static/aboutus/zyzh.png" alt="">
          <p>专业专注</p>
        </li>
        <li>
          <img src="../../static/aboutus/cxjy.png" alt="">
          <p>诚信经营</p>
        </li>
        <li>
          <img src="../../static/aboutus/yzsfw.png" alt="">
          <p>一站式服务</p>
        </li>
        <li>
          <img src="../../static/aboutus/kjzl.png" alt="">
          <p>科技助力</p>
        </li>
      </ul>
      <div class="letterBox">
        <div class="lbSmallBox">
          <div>
            <p>
              <span class="titLb">
                <img src="../../static/aboutus/juxing.png" alt="">
                <div class="spanBox">
                  <span>金银业贸易场AA类行员</span>
                  <span>No. 081</span>
                </div>
              </span>
            </p>
          </div>
          <div>
            Hong Kong Red Lion Capital Limited，简称RLC，是我们为大众倾心打造的独立品牌，旨在向客户提供超低点差和超低利率的交易产品，让客户的投资物超所值，没有后顾之忧。公司专注为客户提供安全、稳定、高效的一站式交易服务。自品牌成立以来，香港红狮集团有限公司一直秉承“客户至上，诚信为本”的经营理念，致力于高科技智能化交易平台的建设，专业、专注、规范操作让香港红狮集团有限公司赢得了客户的高度信赖，同时也得到了业界的广泛关注和大力支持。<br>
            <span v-if="!xsintlg">香港公司注册处公司编号：1448678</span>
          </div>
        </div>
      </div>
      <div class="letterBox">
        <div class="lbSmallBox">
          <div>
            <p>
              <span class="titLb">
                <img src="../../static/aboutus/juxing.png" alt="">
                <div class="spanBox">
                  <span>专业成就未来</span>
                  <span>一站式专业平台</span>
                </div>
              </span>
            </p>
          </div>
          <div>
            香港红狮集团有限公司还为客户提供全面的资讯服务，独到的行情走势分析，重要经济播报和专业在线咨询等，全方位满足投资者的需求。公司采用全球领先的MT4交易平台，为投资者提供一站式交易服务。公司交易平台的稳定性更是备受市场赞誉，支持一秒平仓、EA智能交易，这些都对平台的稳定性有着高度的技术要求，而我们致力于超越行业技术标准。
            <br/>
            展望未来，香港红狮集团有限公司全体同仁将继续实践客户至上的经营理念，努力开拓创新，不断完善和提升产品服务，以帮助投资者实现梦想，共创美好生活。
          </div>
        </div>
      </div>
      <!-- <img class="quxian" src="../../static/aboutus/quxian.png" alt=""> -->
    </div>
    <div v-if="!control" class="partner">
      <img v-if="xsintlg" src="../../static/aboutus/partner.png" alt="">
      <img v-if="!xsintlg" src="../../static/aboutus/partner_xsintlg.png" alt="">
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Aboutus',
    metaInfo: {  
      title: '关于红狮金业,现货黄金开户平台,伦敦金,伦敦银,安全持牌黄金交易平台',  
      meta: [  
        { name: 'keywords', content: '红狮金业,红狮启富,黄金交易平台,贵金属交易,贵金属投资,贵金属平台,现货黄金开户,正规靠谱外汇交易,正规网上交易平台。' },  
        { name: 'description', content: '红狮金业是专业值得信赖的国际投资交易平台，专注于为客户提供24小时全球金融产品在线交易，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },
    data() {
      return{
        control:true,
        xsintlg:true,
      }
    },
    methods:{
      changeModel(){
        this.control = !this.control;
      }
    },
    created(){
      // 传参控制显示顶部和底部
      this.$emit('controlShow', false);
      if(window.location.origin.indexOf('xsintlg')!==-1){
        console.log('我是xsintlg2');
        this.xsintlg = false;
      }
    }
  }
</script>

<style lang="less" scoped>
  .Aboutus{
    width: 100%;
    margin: 0 auto;
    .bigImg{
      width: 100%;
      height: 600px;
      background: url('../../static/aboutus/banner.jpg') no-repeat;
      background-size: cover;
    }
    .amTitle{
      width: 1416px;
      height: 100%;
      margin: 80px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span{
          color: #666666;
          font-size: 34px;
          letter-spacing: 2px;
          margin-bottom: 30px;
        }
        .clickSpan{
          color: #000000;
        }
      }
      p:nth-child(1){
        margin-right: 90px;
      }
    }
    .contentBox{
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding-top: 20px;
      position:relative;
      .contentTop{
        width: 1416px;
        margin: 0 auto 110px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
        p{
          width: 100%;
          height: 60px;
          background-color: #f0f3fc;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          margin-top: -5px;
          font-size: 18px;
        }
      }
      .letterBox{
        width: 1416px;
        height: 344px;
        margin: 0 auto;
        margin-bottom: 70px;
        box-sizing: border-box;
        padding:0 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
        border-radius: 10px;
        .lbSmallBox{
          z-index: 999;
          div:nth-child(1){
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
              width: 100%;
              display: flex;
              height: 50px;
              justify-content: space-between;
              align-items: flex-start;
              span{
                font-weight: 500;
                font-size: 18px;
                color: #2e2e2e;
              }
              .titLb{
                display: flex;
                justify-content: space-between;
                align-items: center;
                img{
                  width: 4px;
                  height: auto;
                  margin-right: 15px;
                }
                .spanBox{
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: center;
                  margin: 0;
                  span{
                    width: 100%;
                    font-weight: 500;
                    font-size: 22px;
                    color: #2e2e2e;
                  }
                  span:nth-child(2){
                    color: #2e2e2e;
                    font-size: 14px;
                    line-height: 24px;
                  }
                }
              }
            }
          }
          div:nth-child(2){
            width: 100%;
            margin-top: 30px;
            font-size: 14px;
            color: #6d6d6d;
            line-height: 30px;
            // text-align: justify;
          }
        }
      }
      .quxian{
        width: 100%;
        height: auto;
        position: absolute;
        bottom: -70px;
        z-index: -999;
      }
    }
    .partner{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 20px 0 100px;
    }
  }
  @media screen and (max-width: 500px) {
    .Aboutus{
      width: 100%;
      margin: 0 auto;
      .bigImg{
        width: 100%;
        height: 450px;
        background: url('../../static/index/wapImg/noticeBanner.jpg') no-repeat;
        background-size: cover;
      }
      .amTitle{
        width: 100%;
        margin: 30px auto;
        p{
          span{
            font-size: 22px;
            letter-spacing: 0;
            margin-bottom: 10px;
          }
          img{
            height: 3px;
          }
        }
      }
      .contentBox{
        padding: 0;
        .contentTop{
          width: 97%;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          li{
            margin-bottom: 10px;
            width: 48%;
            img{
              width: 100%;
            }
            p{
              height: 45px;
              font-size: 16px;
            }
          }
        }
        .letterBox{
          width: 97%;
          margin: 0 auto;
          padding: 0;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 20px 10px 10px;
          height: auto;
          .lbSmallBox{
            div:nth-child(2){
              margin-top: 20px;
            }
          }
        }
      }
      .partner{
        padding: 0px 0 30px;
        img{
          width: 100%;
        }
      }
    }
  }
</style>